<template>
  <div class="option-container">
    <div style="width: 100%;height: 100%;padding: 40px;box-sizing: border-box">
<!--      <div style="display: flex;flex-direction: row">-->
<!--        <p class="question-title">{{ question_title }}</p>-->
<!--      </div>-->
      <div>
        <div v-for="(option,index) in options" :key="index" style="cursor: pointer">
          <div style="display:flex;flex-direction: row;margin-bottom: 20px">
            <p class="option_index" :style="customOptionStyle(option)">
              {{ formatOptionIndexToString(index) }}</p>
            <div class="option-item" :style="customOptionStyle(option)"
                 style="flex: 1;box-sizing: border-box;display: flex;flex-direction: row">
              <p style="flex: 1">{{ option.value }}</p>
              <div v-if="option.reviewOption">
                <img src="../assets/answer_correct.png" style="width: 24px;height: 24px"
                     v-if="option.styleType == 'success'">
                <img src="../assets/answer_error.png" style="width: 24px;height: 24px"
                     v-else-if="option.styleType == 'failure'">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p style="font-size: 16px;color: #333333;font-family:AlibabaPuHuiTi-2-75-SemiBold">Note:</p>
        <p style="margin-top: 8px;font-size: 14px;color: #666666;line-height: 28px;font-family:AlibabaPuHuiTi-2-65-Medium">
          {{ note }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import '../assets/common/font.css'

export default {
  name: "CollectionOptionView",
  props: {
    note: {
      type: String,
      default: ''
    },
    quizInfo: {
      type: Object,
      default: {}
    },
  },
  watch: {
    quizInfo(newVal, oldVal) {
      this.formatQuizInfo(newVal)
    }
  },
  mounted() {
    this.formatQuizInfo(this.quizInfo)
  },
  data() {
    return {
      question_title: '',
      reviewOption: false,
      options: [],
      question_answer: 0
    }
  },
  methods: {
    customOptionStyle(option) {
      let style = {}
      if (option.reviewOption) {
        let styleType = option.styleType
        if (styleType == 'normal') {
          style.background = '#FFFFFF'
          style.color = '#092E7F'
          style.borderColor = '#EEEEEE'
        } else if (styleType == 'failure') {
          style.background = '#FFF5F5'
          style.color = '#FF0000'
          style.borderColor = '#FF0000'
        } else if (styleType == 'success') {
          style.background = '#E4FAF7'
          style.borderColor = '#23B29C'
          style.color = '#23B29C'
        }
      } else {
        if (option.selected) {
          style.background = '#092E7F'
          style.borderColor = '#EEEEEE'
          style.color = '#FFFFFF'
        } else {
          style.background = '#FFFFFF'
          style.color = '#092E7F'
          style.borderColor = '#EEEEEE'
        }
      }
      return style
    },
    formatQuizInfo(newVal) {
      this.resetData()
      this.question_title = newVal.question_title
      this.question_answer = newVal.question_answer
      var correct = false
      var answer = ''

      if (newVal.quiz_record != null) {
        this.reviewOption = true
        this.correct = newVal.quiz_record.correct
        answer = newVal.quiz_record.answer
        correct = newVal.quiz_record.correct
      }
      let temp_options = newVal.question_options
      for (let i = 0; i < temp_options.length; i++) {
        let item = temp_options[i]
        let obj = {
          selected: false,
          reviewOption: this.reviewOption,
          value: item
        }
        if (this.reviewOption) {
          let styleType = this.getOptionStyleType((i + 1), answer, this.question_answer, correct)
          obj['styleType'] = styleType
        }
        this.options.push(obj)
      }
    },
    formatOptionIndexToString(index) {
      switch (index) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
        case 6:
          return 'G'
        case 7:
          return 'H'
      }
    },
    // //normal success ,failure
    getOptionStyleType(index, answer, question_answer, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      if (correct) {
        if (index == answer) {
          styleType = 'success'
        }
      } else {
        if (index == question_answer) {
          styleType = 'success'
        } else if (index == answer) {
          styleType = 'failure'
        }
      }
      return styleType
    },
    resetData() {
      this.reviewOption = false
      this.question_title = ''
      this.options = []
    },
  }
}
</script>

<style scoped lang="less">

.option-container {
  width: 100%;
  height: 100%;
  background: #EFF4FF;
}

.quiz-index {
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #FFA900;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

.option_index {
  margin-right: 20px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092E7F;
}

.question-title {
  font-size: 16px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

.option-item {
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092E7F;
  border: 1px solid #EEEEEE;
}

.option-item:last-child {
  margin-bottom: 0px;
}

.last-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  width: 180px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #092E7F;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #092E7F
}

.next-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  width: 180px;
  height: 40px;
  background: #00348B;
  font-size: 14px;
  font-family: AlibabaPuHuiTi-2-65-Medium;
  font-weight: normal;
  color: #FFFFFF;
}
</style>
